/*!
 * Material Design for Bootstrap 4
 * Version: MDB FREE 4.7.0
 *
 *
 * Copyright: Material Design for Bootstrap
 * https://mdbootstrap.com/
 *
 * Read the license: https://mdbootstrap.com/general/license/
 *
 *
 * Documentation: https://mdbootstrap.com/
 *
 * Getting started: https://mdbootstrap.com/docs/jquery/getting-started/download/
 *
 * Tutorials: https://mdbootstrap.com/education/bootstrap/
 *
 * Templates: https://mdbootstrap.com/templates/
 *
 * Support: https://mdbootstrap.com/support/
 *
 * Contact: office@mdbootstrap.com
 *
 * Attribution: Animate CSS, Twitter Bootstrap, Materialize CSS, Normalize CSS, Waves JS, WOW JS, Toastr, Chart.js , Hammer.js
 *
 */

 @charset "UTF-8";

 // Hausmart
 @import "./fonts";
 @import "./variables";

 $roboto-font-path: "~mdbreact/dist/font/roboto/";
 $image-path: "~mdbreact/dist/img";

 $link-color: $hausmart-pink;

 // Bootstrap
 @import "~mdbreact/dist/scss/core/bootstrap/functions";
 @import "~mdbreact/dist/scss/core/bootstrap/variables";

 // CORE
 @import "~mdbreact/dist/scss/core/mixins";
 // Your custom variables
 @import "~mdbreact/dist/scss/custom-variables";
 @import "~mdbreact/dist/scss/core/colors";
 @import "~mdbreact/dist/scss/core/variables";
 @import "~mdbreact/dist/scss/core/global";
 @import "~mdbreact/dist/scss/core/helpers";
 @import "~mdbreact/dist/scss/core/typography";
 @import "~mdbreact/dist/scss/core/masks";
 @import "~mdbreact/dist/scss/core/waves";

 // FREE
 @import "~mdbreact/dist/scss/free/animations-basic";
 @import "~mdbreact/dist/scss/free/modules/animations-extended/module";
 @import "~mdbreact/dist/scss/free/buttons";
 @import "~mdbreact/dist/scss/free/cards";
 @import "~mdbreact/dist/scss/free/dropdowns";
 @import "~mdbreact/dist/scss/free/input-group";
 @import "~mdbreact/dist/scss/free/navbars";
 @import "~mdbreact/dist/scss/free/pagination";
 @import "~mdbreact/dist/scss/free/badges";
 @import "~mdbreact/dist/scss/free/modals";
 @import "~mdbreact/dist/scss/free/carousels";
 @import "~mdbreact/dist/scss/free/forms";
 @import "~mdbreact/dist/scss/free/msc";
 @import "~mdbreact/dist/scss/free/footers";
 @import "~mdbreact/dist/scss/free/list-group";
 @import "~mdbreact/dist/scss/free/tables";
 @import "~mdbreact/dist/scss/free/depreciated";
 @import "~mdbreact/dist/scss/free/steppers";
 @import "~mdbreact/dist/scss/free/loader";
 // Free addons
 @import "~mdbreact/dist/scss/addons/datatables";

 // Your custom styles
 @import "~mdbreact/dist/scss/custom-styles";
