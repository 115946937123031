@font-face {
    font-family: 'GT Haptik HM';
    src: url('../fonts/GTHaptik/GTHaptikBold.woff2') format('woff2'),
    url('../fonts/GTHaptik/GTHaptikBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Haptik HM';
    src: url('../fonts/GTHaptik/GTHaptikRegular.woff2') format('woff2'),
    url('../fonts/GTHaptik/GTHaptikRegular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

