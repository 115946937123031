@import "./style/variables";

main {
  background-color: #EDEDEE;
}

.navbar-brand {
  margin-left: 15px;
  color: $hausmart-pink !important;
  font-weight: 800;
}

.flexible-content {

}

.sidebar-fixed {
  left: 0;
  top: 0;
  height: 100vh;
  width: 270px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1050;
  background-color: #fff;
  padding: 1.5rem;
  padding-top: 0;
}


.flexible-navbar {
  transition: padding-left 0.5s;
  padding-left: 270px;
  background: #fff;
}

#content {
  min-height: 100%;
  margin-left: 270px;
}


.sidebar-fixed .logo-wrapper img {
  width: 100%;
  padding: 2.5rem;
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: none;
  }

  .flexible-content {
    padding-left: 0;
  }

  .flexible-navbar {
    padding-left: 10px;
  }

  #content {
    margin-left: 0px;
  }

}
