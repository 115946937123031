@import './variables';

body {
  font-family: $font-family-sans-serif;
}

body,
html,
#root {
  height: 100%;
  margin: 0;
}

.text-hausmart-pink {
  color: $hausmart-pink !important;
}

.table-filters th {
  vertical-align: middle !important;
}

.sortable-header th {
  cursor: pointer;
  white-space: nowrap;
}

#filter-button, #export-button {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0.55rem 1.6rem;
}
