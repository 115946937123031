.Login-container {
    /* The image used */
    background-image: url(../images/login-bg.jpg);

    /* Full height */
    min-height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
